// TODO: refactor - there was a lot of duplicated components for mobile and pc view, so I just separated them, but It should be totally reworked.

import React, { useState } from 'react';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { IProduct, ISubstance } from '@magistrmartin/eshop-frontend-shared';
import EText from './EText';
import ProductPropsSummary from './ProductPropsSummary';
import { round, scrollToRefObject } from '../Utils/Common';
import CzechProductIcon from './CzechProductIcon';
import { showCzechIcon } from '../Utils/Product';

const gridBorder = '1px solid #dfdfdf';
const gridRowPadding = '6px 0';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 8,
    },
    header: {
      backgroundColor: theme.palette.primary.main,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      padding: 12,
      display: 'flex',
      color: theme.palette.primary.contrastText,
    },
    headerItem: {
      padding: 4,
      paddingRight: 22,
      paddingLeft: 22,
      cursor: 'pointer',
      fontFamily: 'Roboto',
    },
    body: {
      padding: 34,
      border: `1px solid ${theme.palette.primary.main}`,
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    descriptionFrame: {
      border: 'none',
      width: '100%',
    },
    flexRow: {
      display: 'flex',
      alignItems: 'center',
      gap: '15px',
    },
    additionalInfoGrid: {
      display: 'grid',
      // columnGap: '20px',
      gridTemplateColumns: 'auto 1fr',
      gridTemplateRows: 'repeat(5, min-content)',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'auto',
      },
    },

    additionalInfoCellFirst: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderBottom: gridBorder,
      padding: gridRowPadding,

      [theme.breakpoints.down('sm')]: {
        borderBottom: 'none',
        padding: 0,
      },
    },
    additionalInfoCellSecond: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingLeft: '15px',
      borderBottom: gridBorder,
      padding: gridRowPadding,

      [theme.breakpoints.down('sm')]: {
        borderBottom: 'none',
        paddingLeft: 0,
        padding: '2px 0',

        '&:nth-child(even)': {
          marginBottom: '15px',
        },
      },
    },
  })
);

interface IProps {
  product: IProduct;
  similarProductsRef: any;
  reference: any; // TODO ref type
}

const ManufacturerCellContent = ({ product }: { product: IProduct }) => {
  const classes = useStyles();
  return (
    <div className={classes.flexRow}>
      <EText>{product.manufacturer}</EText>
      {showCzechIcon(product) && <CzechProductIcon />}
    </div>
  );
};

const SubstancesList = ({ substances }: { substances: ISubstance[] }) => (
  <EText>
    {substances.map((sub, i) =>
      sub.noColoring && sub.noConservants && sub.noSweeteners ? (
        <React.Fragment key={sub.id}>
          {sub.name}
          {i < substances.length - 1 && ', '}
        </React.Fragment>
      ) : (
        <span key={sub.id} style={{ color: 'red' }}>
          {sub.name}
          {i < substances.length - 1 && ', '}
        </span>
      )
    )}
  </EText>
);

const ProductAgingText = ({ product }: { product: IProduct }) => (
  <EText>
    {product.age !== undefined ? (
      <>
        {product.age < 1 ? `${round(product.age * 12, 0)} měsíců` : `${product.age} let`} (Minimální věk, od kterého je
        produkt určen)
      </>
    ) : (
      'Nezadáno'
    )}
  </EText>
);

const ProductMassText = ({ product }: { product: IProduct }) => (
  <EText>
    {product.mass !== undefined ? <>{product.mass} kg (Minimální hmotnost, od které je produkt určen)</> : 'Nezadáno'}
  </EText>
);

export default function DescriptionPanel({ product, reference, similarProductsRef }: IProps) {
  const classes = useStyles();
  const theme = useTheme();
  const [frameHeight, setFrameHeight] = useState('0px');

  const iframeReady = () => {
    let iframe = document.getElementById('iframeTarget') as HTMLIFrameElement;
    //@ts-ignore
    let iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
    if (iframeWin.document.body)
      setFrameHeight((iframeWin.document.documentElement.scrollHeight || iframeWin.document.body.scrollHeight) + ' px');
  };

  return (
    <div ref={reference} className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerItem}>Popis produktu</div>
        {similarProductsRef && similarProductsRef.current && (
          <div className={classes.headerItem} onClick={() => scrollToRefObject(similarProductsRef)}>
            Podobné produkty v kategorii
          </div>
        )}
      </div>
      <div className={classes.body}>
        <EText variant="h6">Popis produktu</EText>
        <br />
        <iframe
          id="iframeTarget"
          className={classes.descriptionFrame}
          onLoad={iframeReady}
          srcDoc={`<head><link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" /></head><body><div style='font-family: "Roboto"; color: ${theme.palette.primary.main}'>${product?.description}</div></body>`}
          title="DescriptionFrame"
          height={frameHeight}
        />
        <br />
        <br />
        <EText style={{ fontSize: '1.5em' }}>
          <b>Doplňující informace</b>
        </EText>
        <br />
        <div className={classes.additionalInfoGrid}>
          <div className={classes.additionalInfoCellFirst}>
            <EText>
              <b>Výrobce</b>
            </EText>
          </div>
          <div className={classes.additionalInfoCellSecond}>
            <ManufacturerCellContent product={product} />
          </div>
          <div className={classes.additionalInfoCellFirst}>
            <EText>
              <b>Dodavatel&nbsp;na&nbsp;trh</b>
            </EText>
          </div>
          <div className={classes.additionalInfoCellSecond}>
            <a href={`/offer/supplier/${product.supplier}`}>
              <EText underline>{product.supplier}</EText>
            </a>
          </div>

          <div className={classes.additionalInfoCellFirst}>
            <EText>
              <b>Značka</b>
            </EText>
          </div>
          <div className={classes.additionalInfoCellSecond}>
            <a href={`/offer/brand/${product.brandMark}`}>
              <EText underline>{product.brandMark}</EText>
            </a>
          </div>
          <div className={classes.additionalInfoCellFirst}>
            <EText>
              <b>Produktová&nbsp;řada</b>
            </EText>
          </div>
          <div className={classes.additionalInfoCellSecond}>
            <a href={`/offer/productLine/${product.series}`}>
              <EText underline>{product.series}</EText>
            </a>
          </div>
          <div className={classes.additionalInfoCellFirst}>
            <EText>
              <b>Kód&nbsp;SÚKL</b>
            </EText>
          </div>
          <div className={classes.additionalInfoCellSecond}>
            <EText>{product.sukl}</EText>
          </div>
          <div className={classes.additionalInfoCellFirst}>
            <EText>
              <b>Farmakoterapeutická&nbsp;skupina</b>
            </EText>
          </div>
          <div className={classes.additionalInfoCellSecond}>
            <EText>{product.pharmaGroup}</EText>
          </div>
          <div className={classes.additionalInfoCellFirst}>
            <EText>
              <b>Charakteristika</b>
            </EText>
          </div>
          <div className={classes.additionalInfoCellSecond}>
            <EText>{product.characteristics}</EText>
          </div>
          <div className={classes.additionalInfoCellFirst}>
            <EText>
              <b>Upozornění</b>
            </EText>
          </div>
          <div className={classes.additionalInfoCellSecond}>
            <EText align="justify">{product.warning}</EText>
          </div>
          <div className={classes.additionalInfoCellFirst}>
            <EText>
              <b>Forma</b>
            </EText>
          </div>
          <div className={classes.additionalInfoCellSecond}>
            <EText>{product.form}</EText>
          </div>
          <div className={classes.additionalInfoCellFirst}>
            <EText>
              <b>Účinná/Aktivní&nbsp;látka</b>
            </EText>
          </div>
          <div className={classes.additionalInfoCellSecond}>
            <EText>{product.activeSubstances?.join(', ')}</EText>
          </div>
          <div className={classes.additionalInfoCellFirst}>
            <EText>
              <b>Pomocné&nbsp;látky</b>
            </EText>
          </div>
          <div className={classes.additionalInfoCellSecond}>
            {product.substances && <SubstancesList substances={product.substances} />}
          </div>
          <div className={classes.additionalInfoCellFirst}>
            <EText>
              <b>Věková&nbsp;osa</b>
            </EText>
          </div>
          <div className={classes.additionalInfoCellSecond}>
            <ProductAgingText product={product} />
          </div>

          <div className={classes.additionalInfoCellFirst}>
            <EText>
              <b>Hmotnostní&nbsp;osa</b>
            </EText>
          </div>
          <div className={classes.additionalInfoCellSecond}>
            <ProductMassText product={product} />
          </div>
        </div>

        <br />
        <br />
        <ProductPropsSummary product={product} />
        <br />
        <br />
        <br />

        <Alert severity="warning">
          Uvedené informace se v průběhu doby mohou lišit. Vždy se řiďte dle informací výrobce uvedených na příbalovém
          letáku.
        </Alert>
        <br />
      </div>
    </div>
  );
}
