import { Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React from 'react';
import EText from '../../Components/EText';
import { IStepsInfo } from './IStepsInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCard: {},
    flexGrow: { flexGrow: 1 },
    flex: { display: 'flex', justifyContent: 'space-around' },
  })
);

interface Props {
  stepsInfo: IStepsInfo[];
  step: number;
  setStep: (num: number) => void;
  createOrder: () => void;
  createOrderLoading: boolean;
}

const NextPrevButtons = ({ stepsInfo, step, setStep, createOrder, createOrderLoading: loading }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const lastStepIndex = stepsInfo.length - 1;

  return (
    <>
      <div className={classes.flex}>
        {stepsInfo[step].prevBtnText && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              step === 0 ? history.goBack() : setStep(step - 1);
              window.scrollTo({ top: 0, left: 0 });
            }}
          >
            {stepsInfo[step].prevBtnText}
          </Button>
        )}
        <span className={classes.flexGrow}>&nbsp;</span>
        {stepsInfo[step].nextBtnText && (
          <Button
            variant="contained"
            color="secondary"
            disabled={loading || !stepsInfo[step].canContinue}
            style={
              loading || !stepsInfo[step].canContinue
                ? {}
                : {
                    backgroundColor: '#84B517',
                    color: 'white',
                  }
            }
            onClick={() => {
              step < lastStepIndex ? setStep(step + 1) : createOrder();
              window.scrollTo({ top: 0, left: 0 });
            }}
          >
            {loading ? 'Odesílám zásilku' : stepsInfo[step].nextBtnText}
          </Button>
        )}
      </div>
      {stepsInfo[step].nextBtnText.toLowerCase() === 'odeslat objednávku' && (
        <div style={{ width: '100%', paddingTop: 8, textAlign: 'right' }}>
          <EText align="right" variant="caption">
            Odesíláte objednávku s povinností převzetí a platby.
          </EText>
        </div>
      )}
    </>
  );
};

export default NextPrevButtons;
