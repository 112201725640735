import React from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import PlaceSelectMap from './PlaceSelectMap';
import Environment from '../Environments';
import EText from './EText';
import { usePickupPlaces } from '../Hooks/PickupPlaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      borderRadius: '8px',
      //   marginTop: 32,
      marginBottom: 32,
      cursor: 'pointer',
      alignItems: 'center',
    },
    title: {
      color: 'white',
      fontFamily: 'Roboto',
      marginBottom: 4,
    },
    subtitle: {
      color: 'white',
      fontFamily: 'Roboto',
      fontSize: '0.9rem',
      marginTop: 2,
    },
    icon: {
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      height: 36,
      width: 48,
      minWidth: 48,
      textAlign: 'center',
      marginLeft: 18,
      marginRight: 18,
      // marginTop: 20,
      paddingTop: 12,
      fontSize: '1.2rem',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    checkIconSmall: {
      borderRadius: '50%',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      fontSize: 16,
      paddingLeft: 4,
      paddingTop: 1,
      height: 19,
      width: 16,
      margin: 8,
    },
  })
);

interface IParams {
  product: IProduct;
}

export default function InStockBanner({ product }: IParams) {
  const classes = useStyles();
  const { data: pickupPlaces = [] } = usePickupPlaces();
  const history = useHistory();

  const availablePlaces = pickupPlaces.filter(
    (p) => (product?.stockState?.find((ss) => ss.placeId === p.id)?.ammount || 0) > 0
  );
  const isInStock =
    product.unavailable !== true &&
    ((product.stockAmmount || 0) > 0 ||
      ((product.expiration || '') !== '' && new Date(product.expiration || '').getFullYear() > 2000));

  return (
    <>
      {Environment.showReservation && availablePlaces.length > 0 && (
        <PlaceSelectMap
          places={availablePlaces}
          renderOpener={(open) => (
            <Button onClick={open} style={{ marginTop: 8, marginBottom: 8 }} color="secondary">
              Okamžitě k rezervaci na {availablePlaces.length} {availablePlaces.length > 1 ? 'lékárnách' : 'lékárně'}
            </Button>
          )}
        />
      )}
      <div
        className={classes.root}
        style={{
          backgroundColor: isInStock ? '#3ebd4f' : product.unavailable === true ? 'lightgrey' : '#f28313',
          paddingLeft: isInStock ? 0 : 16,
          marginTop: availablePlaces.length === 0 ? 20 : undefined,
        }}
        onClick={() => history.push('/DeliveryAndPayment')}
      >
        {isInStock && (
          <div className={classes.icon}>
            <FontAwesomeIcon color="white" icon={faBox} />
          </div>
        )}
        <span>
          <p
            className={classes.title}
            style={{
              color: product.unavailable === true ? '#000000' : '#FFFFFF',
            }}
          >
            <b>
              {isInStock ? (
                <>Skladem v online lékárně</>
              ) : product.unavailable === true ? (
                <>Produkt ve výpadku</>
              ) : (
                <>Není skladem v online lékárně – pracujeme na doskladnění</>
              )}
            </b>
          </p>
          <div
            className={classes.subtitle}
            style={{
              color: product.unavailable === true ? '#000000' : '#FFFFFF',
            }}
          >
            {isInStock ? (
              <>{Environment.fullName} brzy odešle, u vás zpravidla 1-2 dny od odeslání.</>
            ) : product.unavailable === true ? (
              <>Omlouváme se, ale produkt je momentálně na trhu ve výpadku a není dlouhodobě dostupný.</>
            ) : (
              <>
                Zboží doplňujeme pravidelně 1x denně, v případě plošného výpadku na trhu může být produkt dlouhodobě
                nedostupný.
              </>
            )}
            <br /> Přehledně <u>Doprava a platba</u>
            <br />
            {isInStock && (
              <div className={classes.flex}>
                <div className={classes.checkIconSmall}>✔</div>
                <EText style={{ color: 'white' }}>Přes 10 000 odběrných míst v ČR</EText>
              </div>
            )}
          </div>
        </span>
      </div>
    </>
  );
}
