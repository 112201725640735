import React, { useEffect, useState } from 'react';
import { createStyles, Grid, Hidden, makeStyles, Theme } from '@material-ui/core';
import EText from '../Components/EText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { ITreeCategory } from '../Types/base';
import { useHistory } from 'react-router-dom';
import { loadCategories, loadCategoriesFromApi } from '../Utils/CategoriesService';
import MenuMagistrRecommendsCard from '../Components/MenuMagistrRecommendsCard';
import { IProduct } from '@magistrmartin/eshop-frontend-shared';
import { catalogService } from '../Utils/ApiService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      width: 'calc(100%-12px)',
      height: 64,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      textTransform: 'uppercase',
      fontSize: '10pt',
      borderRadius: 12,
      fontWeight: 'revert',
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: 22,
      [theme.breakpoints.down('md')]: {
        fontSize: '9pt',
        height: 48,
      },
    },
    menuTop: {
      float: 'left',
      backgroundColor: theme.palette.secondary.main,
      height: '48px',
      paddingTop: '16px',
      paddingLeft: '16px',
      paddingRight: '16px',
      borderTopLeftRadius: '12px',
      borderBottomLeftRadius: '12px',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingTop: '12px',
        height: '36px',
      },
    },
    menuLoading: {
      textAlign: 'center',
      height: '56px',
      marginTop: '6px',
      paddingTop: '0px',
      paddingLeft: '10px',
      paddingRight: '10px',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '2px',
        paddingRight: '3px',
        paddingTop: '4px',
        height: '38px',
      },
    },
    menuItem: {
      textAlign: 'center',
      float: 'left',
      height: '56px',
      marginTop: '6px',
      paddingTop: '0px',
      paddingLeft: '10px',
      paddingRight: '10px',
      maxWidth: '60px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('lg')]: {
        '&:hover': {
          backgroundColor: 'white',
          color: theme.palette.primary.main,
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          '& $menuSubcatsContainer': {
            visibility: 'visible',
            zIndex: 999,
          },
        },
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: '2px',
        paddingRight: '3px',
        paddingTop: '4px',
        height: '38px',
      },
    },
    menuSubcatsContainer: {
      backgroundColor: 'white',
      cursor: 'default',
      position: 'absolute',
      left: 'calc(50vw - 624px)',
      top: '176px',
      padding: '18px',
      width: 'min(1194px, 100vw)',
      visibility: 'hidden',
      border: `1px solid ${theme.palette.primary.main}`,
      borderTop: 'none',
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
    },
    subCategoriesHeader: {
      margin: '16px',
      textAlign: 'left',
    },
    subCategoriesList: {
      listStyle: 'none',
      textTransform: 'none',
      textAlign: 'left',
      '& li': {
        margin: '8px',
      },
    },
    clickable: {
      cursor: 'pointer',
      '&:hover': {
        fontWeight: 'bold',
      },
    },

    flex: {
      display: 'flex',
      justifyContent: 'space-between',
    },

    button: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontFamily: 'Roboto',
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 12,
      paddingBottom: 12,
      cursor: 'pointer',
      borderRadius: 8,
      position: 'absolute',
      right: 16,
      bottom: 16,
    },
  })
);

export default function Menu() {
  const classes = useStyles();
  const [categories, setCategories] = useState<ITreeCategory[]>([]);
  const [topProducts, setTopProducts] = useState<IProduct[]>([]);
  const [autoHide, setAutoHide] = useState(false);
  const history = useHistory();

  const changeCategories = (categories: ITreeCategory[]) => {
    setCategories(categories);
    const idsToFetch = categories.map((c) => c.topProductId).filter((p) => p);
    if (idsToFetch.find((id) => topProducts.find((tp) => tp.id === id) === undefined)) {
      catalogService.get(
        '/products/noauth/list',
        { ids: idsToFetch, mapped: true },
        {
          success: setTopProducts,
          error: () => setTopProducts([]),
        }
      );
    }
  };

  useEffect(() => {
    if (loadCategories(changeCategories)) {
      loadCategoriesFromApi(changeCategories);
    }
    //eslint-disable-next-line
  }, []);

  const cancelHover = () => {
    setAutoHide(true);
    setTimeout(() => setAutoHide(false), 100);
  };

  return (
    <div className={classes.menu}>
      <div className={classes.menuTop} onClick={() => history.push('/')}>
        <FontAwesomeIcon icon={faTag} />
        &nbsp;&nbsp;Akce
        <br /> a slevy
      </div>
      {categories.length === 0 && <div className={classes.menuLoading}>Kategorie se načítají</div>}
      {categories?.map((c) => (
        <div
          key={c.description}
          className={classes.menuItem}
          onClick={() => {
            history.push(`/offer/category/${c.description?.replace(/%/g, '')}-${c.id}`);
          }}
        >
          {c.description.toLowerCase() === 'homeopatika' ? 'Homeo patika' : c.description.replace(/ a /g, ' a\u00A0')}
          <Hidden mdDown>
            {!autoHide && (
              <div className={classes.menuSubcatsContainer} onClick={(e) => e.stopPropagation()}>
                <Grid container>
                  <Grid item xs={7}>
                    <EText variant="h6" className={classes.subCategoriesHeader}>
                      {c.description}
                    </EText>
                    <Grid container>
                      <Grid item xs={4}>
                        <ul className={classes.subCategoriesList}>
                          {c.subCategories
                            .filter((_, i) => i % 3 === 0)
                            .map((sc) => (
                              <li
                                key={sc.description}
                                className={classes.clickable}
                                onClick={(e) => {
                                  cancelHover();
                                  history.push(`/offer/category/${sc.description?.replace(/%/g, '')}-${sc.id}`);
                                }}
                              >
                                {sc.description}
                              </li>
                            ))}
                        </ul>
                      </Grid>
                      <Grid item xs={4}>
                        <ul className={classes.subCategoriesList}>
                          {c.subCategories
                            .filter((_, i) => i % 3 === 1)
                            .map((sc) => (
                              <li
                                key={sc.description}
                                className={classes.clickable}
                                onClick={() => {
                                  cancelHover();
                                  history.push(`/offer/category/${sc.description?.replace(/%/g, '')}-${sc.id}`);
                                }}
                              >
                                {sc.description}
                              </li>
                            ))}
                        </ul>
                      </Grid>
                      <Grid item xs={4}>
                        <ul className={classes.subCategoriesList}>
                          {c.subCategories
                            .filter((_, i) => i % 3 === 2)
                            .map((sc) => (
                              <li
                                key={sc.description}
                                className={classes.clickable}
                                onClick={() => {
                                  cancelHover();
                                  history.push(`/offer/category/${sc.description?.replace(/%/g, '')}-${sc.id}`);
                                }}
                              >
                                {sc.description}
                              </li>
                            ))}
                        </ul>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    {c.topProductId && (
                      <MenuMagistrRecommendsCard product={topProducts.find((p) => p.id === c.topProductId)} />
                    )}
                  </Grid>
                </Grid>
              </div>
            )}
          </Hidden>
        </div>
      ))}
    </div>
  );
}
