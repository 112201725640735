import React, { useEffect, useState } from 'react';
import { Container, createStyles, makeStyles, Theme } from '@material-ui/core';
import ShoppingCart from './ShoppingCart';
import { reducers } from '../../Utils/Reducers';
import { useLayout } from '../../Layout/LayoutContext';
import { IProduct, IProfile } from '@magistrmartin/eshop-frontend-shared';
import { DiscountsStatus } from '../../Utils/Discounts';
import { getTotalProductPrice } from '../../Utils/Product';
import TotalSavingsPanel from './TotalSavingsPanel';
import StepCard from '../../Components/StepCard';
import ContactInfo from './ContactInfo';
import DeliveryAndPayment from './DeliveryAndPayment';
import OrderRecapitulation from './OrderRecapitulation';
import { catalogService, imagesService, ordersService } from '../../Utils/ApiService';
import { Order } from '../../Types/order';
import { HomeWork, LocalShipping, Visibility, ShoppingCart as ShoppingCartIcon } from '@material-ui/icons';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ICentralPresent,
  ShippingPaymentConfiguration,
  DeliveryInfo,
  OrderedProduct,
} from '@magistrmartin/eshop-frontend-shared';
import Environment from '../../Environments';
import { IStepsInfo } from './IStepsInfo';
import NextPrevButtons from './NextPrevButtons';
import useCart, { CartState } from '../../Stores/cartStore';
import CartItem from '../../Types/cartItem';
import { usePickupPlaces } from '../../Hooks/PickupPlaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCard: {},
    flexGrow: { flexGrow: 1 },
    flex: { display: 'flex', justifyContent: 'space-around' },
  })
);

export type deliveryType = 'personalPickup' | 'packetaHome' | 'packeta';
export type payment = 'bankTransaction' | 'paymentGate' | 'pickup';
export type packetaInfo = {
  id: number;
  name: string;
  url: string;
};

function totalMass(products: CartItem[]) {
  return products.reduce((totalMass, { product, amount }) => {
    if (product.productMass !== undefined) {
      const mass = product.productMass * amount;
      totalMass += mass;
    }
    return totalMass;
  }, 0);
}

// if total product mass is higher than 14 kg, add additional price
// Zásilkovna has limit of 14kg for one package
function additionalDeliveryPrice(totalMass: number) {
  return Math.ceil((totalMass + 1) / 14000);
}

export default function FinishOrder() {
  const classes = useStyles();
  const layout = useLayout();
  const { isAuthenticated } = useAuth0();

  const products: CartItem[] = useCart((state: CartState) => state.getProducts());
  const clearCart = useCart((state: CartState) => state.clear);

  const [allCentralPresents, setAllCentralPresents] = useState<ICentralPresent[]>([]);
  const [delivery, setDelivery] = useState<deliveryType>('packetaHome');
  const [payment, setPayment] = useState<payment>('pickup');
  const [packetaInfo, setPacketaInfo] = useState<packetaInfo | undefined>(undefined);
  const [deliveryDetails, setDeliveryDetails] = useState<IProfile>({});
  const [step, setStep] = useState(0);
  const [deliveryPaymentConfig, setDeliveryPaymentConfig] = useState<ShippingPaymentConfiguration | undefined>(
    undefined
  );
  const [createOrderLoading, setCreateOrderLoading] = useState(false);
  const [presents, setPresents] = useState<IProduct[]>([]);
  const [doNotUseHeurekaSatisfy, setDoNotUseHeurekaSatisfy] = useState(false);
  const [isContactInfoValid, setIsContactInfoValid] = useState(false);
  const [contactPageVisited, setContactPageVisited] = useState(false);
  const [differentDelivery, setDifferentDelivery] = useState(false);
  const { data: pickupPlaces = [] } = usePickupPlaces();

  const discountsStatus = new DiscountsStatus(
    products,
    allCentralPresents,
    isAuthenticated,
    layout.homeConfig.todaysSpecial
  );

  const expectedPrice = products
    .map((p) => p.amount * (p.product.reccomendedPrice || p.product.actionPrice || 0))
    .reduce(reducers.sum, 0);

  const totalPriceWithoutDelivery = useCart((state: CartState) => state.getCartPrice)(layout.homeConfig.todaysSpecial);

  const paymentPrice =
    delivery === 'personalPickup'
      ? 0
      : isAuthenticated
        ? (deliveryPaymentConfig?.paymentPrices[payment]?.freeFromRegistered || 99999999) <= totalPriceWithoutDelivery
          ? deliveryPaymentConfig?.paymentPrices[payment]?.priceRegistered || 0
          : (deliveryPaymentConfig?.paymentPrices[payment]?.freeFrom || 99999999) <= totalPriceWithoutDelivery
            ? 0
            : deliveryPaymentConfig?.paymentPrices[payment]?.price || 0
        : (deliveryPaymentConfig?.paymentPrices[payment]?.freeFrom || 99999999) <= totalPriceWithoutDelivery
          ? 0
          : deliveryPaymentConfig?.paymentPrices[payment]?.price || 0;

  const totalProductMass = totalMass(products);
  const addDeliveryPrice = additionalDeliveryPrice(totalProductMass);

  const deliveryPrice =
    discountsStatus.isDeliveryFree() ||
    (deliveryPaymentConfig?.shippingPrices[delivery]?.freeFrom || 99999999) <= totalPriceWithoutDelivery
      ? 0
      : isAuthenticated &&
          (deliveryPaymentConfig?.shippingPrices[delivery]?.freeFromRegistered || 999999999) <=
            totalPriceWithoutDelivery
        ? deliveryPaymentConfig?.shippingPrices[delivery]?.priceRegistered || 0
        : deliveryPaymentConfig?.shippingPrices[delivery]?.price || 0;

  const totalPrice = totalPriceWithoutDelivery + paymentPrice + deliveryPrice * addDeliveryPrice;

  useEffect(
    () => {
      imagesService.get(
        '/text',
        {
          file: Environment.domain.replace('.', '_') + '_shippingPayment.json',
          location: 'config',
        },
        {
          success: setDeliveryPaymentConfig,
          error: () => layout.error('Při načítání cen dopravy a platby došlo k chybě'),
        }
      );
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    catalogService.get(
      '/products/noauth/list',
      {
        ids: discountsStatus.getPresents().map((g) => g.presentId),
      },
      {
        success: setPresents,
        error: console.log,
      }
    );
    // eslint-disable-next-line
  }, [allCentralPresents, isAuthenticated]);

  useEffect(() => {
    catalogService.get(
      '/discounts/centralPresents',
      {},
      {
        success: setAllCentralPresents,
        error: console.log,
      }
    );
    // eslint-disable-next-line
  }, []);

  const profileToDeliveryInfo = () => {
    return {
      deliveryType: delivery,
      email: deliveryDetails.email,
      phone: deliveryDetails.address?.phone,
      name: deliveryDetails.address?.name,
      surname: deliveryDetails.address?.surname,
      street: deliveryDetails.address?.street,
      houseNum: deliveryDetails.address?.houseNum,
      zip: deliveryDetails.address?.zip,
      city: deliveryDetails.address?.city,
      company: deliveryDetails.address?.company,
      companyId: deliveryDetails.companyId,
      vat: deliveryDetails.vat,
      delPhone: deliveryDetails.deliveryAddress?.phone,
      delComplany: deliveryDetails.deliveryAddress?.company,
      deliveryPrice: deliveryPrice,
      packetaPaceName: packetaInfo?.name,
      packetaPlaceId: packetaInfo?.id,
      packetaPlaceUrl: packetaInfo?.url,
      ...(differentDelivery
        ? {
            delName: deliveryDetails.deliveryAddress?.name,
            delSurname: deliveryDetails.deliveryAddress?.surname,
            delStreet: deliveryDetails.deliveryAddress?.street,
            delHouseNum: deliveryDetails.deliveryAddress?.houseNum,
            delZip: deliveryDetails.deliveryAddress?.zip,
            delCity: deliveryDetails.deliveryAddress?.city,
          }
        : {}),
    } as DeliveryInfo;
  };

  const mapDataToOrderType = (): Order => {
    return {
      usedDiscountCode: '',
      paymentType: payment,
      totalPrice: totalPrice,
      totalSavings: expectedPrice - totalPriceWithoutDelivery,
      paymentPrice: paymentPrice,
      status: 'Waiting',
      paid: false,
      remark: '',
      userId: deliveryDetails.userId,
      domain: Environment.domain,
      orderedProductsList: [
        ...products.map((p) => {
          const orderedProduct: OrderedProduct = {
            // for type checking divided into object and return statements
            productId: p.product.id,
            orderId: -1,
            productTitle: p.product.title,
            productSubtitle: p.product.subtitle,
            defaultPrice: p.product.reccomendedPrice,
            finalPrice: getTotalProductPrice(p.amount, p.product, discountsStatus, layout.homeConfig.todaysSpecial),
            totalAmmount: p.amount,
            freeAmmount: discountsStatus.getNumberOfFreeUnits(p.product),
            remark:
              p.product.secondItemDiscount && p.product.secondItemDiscount.discount
                ? `Každý 2. kus o ${p.product.secondItemDiscount.discount.value} ${
                    p.product.secondItemDiscount.discount.factor === 'absolute' ? ' Kč' : '%'
                  } levněji`
                : '',
          };
          return orderedProduct;
        }),
        ...discountsStatus.getPresents().map((p) => {
          const receivedPresent = {
            productId: p.presentId,
            productTitle: presents.filter((pres) => p.presentId === pres.id)[0].title,
            productSubtitle: presents.filter((pres) => p.presentId === pres.id)[0].subtitle,
            defaultPrice: 0,
            finalPrice: 0,
            totalAmmount: p.ammount,
            freeAmmount: p.ammount,
            remark: 'PRESENT',
          };
          return receivedPresent;
        }),
      ],
      deliveryInfo: profileToDeliveryInfo(),
      statusChangeHistoryList: [],
    };
  };

  const createOrder = () => {
    setCreateOrderLoading(true);
    const order = mapDataToOrderType();
    ordersService.post('/noauth/', { doNotRegisterToHeureka: doNotUseHeurekaSatisfy }, order, {
      success: (redirect: any) => {
        clearCart();
        window.location.href = redirect.location;
        setCreateOrderLoading(false);
      },
      error: () => {
        layout.error(
          'Při vytváření objednávky došlo k chybě, zkuste to prosím později a případně kontaktujte technickou podporu.'
        );
        setCreateOrderLoading(false);
      },
    });
  };

  const stepsInfo: IStepsInfo[] = [
    {
      element: (
        <ShoppingCart
          totalPrice={totalPriceWithoutDelivery}
          presents={presents}
          products={products}
          discountsStatus={discountsStatus}
          totalProductMass={totalProductMass}
        />
      ),
      prevBtnText: 'ZPĚT - POKRAČOVAT V NÁKUPU',
      nextBtnText: 'DÁLE - DOPRAVA A PLATBA',
      canContinue: products.length > 0,
    },
    {
      element: (
        <DeliveryAndPayment
          places={pickupPlaces}
          products={products.filter((p) => p.amount > 0).map((p) => p.product)}
          discountsStatus={discountsStatus}
          packetaInfo={packetaInfo}
          setPacketaInfo={setPacketaInfo}
          delivery={delivery}
          setDelivery={setDelivery}
          payment={payment}
          setPayment={setPayment}
          totalPrice={totalPriceWithoutDelivery}
          paymentPrice={paymentPrice}
          deliveryPrice={deliveryPrice}
          deliveryPriceMultiplier={addDeliveryPrice}
          config={deliveryPaymentConfig}
        />
      ),
      prevBtnText: 'ZPĚT - NÁKUPNÍ KOŠÍK',
      nextBtnText: 'DÁLE - KONTAKTNÍ ÚDAJE',
      canContinue: true,
    },
    {
      element: (
        <ContactInfo
          deliveryDetails={deliveryDetails}
          setIsValid={setIsContactInfoValid}
          setDeliveryDetails={setDeliveryDetails}
          deliveryType={delivery}
          paymentType={payment}
          contactPageVisited={contactPageVisited}
          setContactPageVisited={setContactPageVisited}
          differentDelivery={differentDelivery}
          setDifferentDelivery={setDifferentDelivery}
        />
      ),
      prevBtnText: 'ZPĚT - DOPRAVA A PLATBA',
      nextBtnText: 'REKAPITULACE OBJEDNÁVKY',
      canContinue: isContactInfoValid,
    },
    {
      element: (
        <OrderRecapitulation
          presents={presents}
          products={products}
          deliveryDetails={deliveryDetails}
          deliveryType={delivery}
          paymentType={payment}
          discountsStatus={discountsStatus}
          packetaInfo={packetaInfo}
          deliveryPrice={deliveryPrice}
          paymentPrice={paymentPrice}
          totalPrice={totalPrice}
          deliveryPaymentConfig={deliveryPaymentConfig}
          doNotUseHeurekaSatisfy={doNotUseHeurekaSatisfy}
          setDoNotUseHeurekaSatisfy={setDoNotUseHeurekaSatisfy}
          differentDelivery={differentDelivery}
        />
      ),
      prevBtnText: 'ZPĚT - KONTAKTNÍ ÚDAJE',
      nextBtnText: 'ODESLAT OBJEDNÁVKU',
      canContinue: true,
    },
  ];

  return (
    <Container maxWidth="lg">
      <div className={classes.flex}>
        <StepCard
          icon={ShoppingCartIcon}
          selected={step >= 0}
          finished={step > 0}
          text="Nákupní košík"
          onClick={step === 0 ? undefined : () => setStep(0)}
        />
        <StepCard
          icon={LocalShipping}
          selected={step >= 1}
          finished={step > 1}
          text="Doprava a platba"
          onClick={
            step < 0
              ? undefined
              : step === 0
                ? stepsInfo[step].canContinue
                  ? () => setStep(1)
                  : undefined
                : () => setStep(1)
          }
        />
        <StepCard
          icon={HomeWork}
          selected={step >= 2}
          finished={step > 2}
          text="Kontaktní údaje"
          onClick={
            step < 1
              ? undefined
              : step === 1
                ? stepsInfo[step].canContinue
                  ? () => setStep(2)
                  : undefined
                : () => setStep(2)
          }
        />
        <StepCard
          icon={Visibility}
          selected={step >= 3}
          text="Rekapitulace objednávky"
          onClick={
            step < 2
              ? undefined
              : step === 2
                ? stepsInfo[step].canContinue
                  ? () => setStep(3)
                  : undefined
                : () => setStep(3)
          }
          isLast
        />
      </div>
      <br />
      <br />
      <br />
      <NextPrevButtons
        createOrder={createOrder}
        createOrderLoading={createOrderLoading}
        setStep={setStep}
        step={step}
        stepsInfo={stepsInfo}
      />
      <br />
      <br />
      {stepsInfo[step].element}

      <br />
      <TotalSavingsPanel expectedPrice={expectedPrice} actualPrice={totalPriceWithoutDelivery} />
      <br />
      <NextPrevButtons
        createOrder={createOrder}
        createOrderLoading={createOrderLoading}
        setStep={setStep}
        step={step}
        stepsInfo={stepsInfo}
      />
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
}
